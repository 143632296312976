body,
html {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Inter', 'Archivo', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url(assets/fonts/Inter-VariableFont_slnt\,wght.ttf) format('truetype');
}

@font-face {
  font-family: 'Archivo';
  src: local('Archivo'), url(assets/fonts/Archivo-VariableFont_wdth\,wght.ttf) format('truetype');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Stilizarea scrollbarului pentru WebKit (Chrome, Safari) */
.ant-table-body::-webkit-scrollbar {
  width: 6px;
  /* Lățimea scrollbarului */
}

.ant-table-body::-webkit-scrollbar-thumb {
  background-color: #001529;
  /* Culoarea scrollbarului */
  border-radius: 10px;
  /* Rotunjirea colțurilor */
}

.ant-table-body::-webkit-scrollbar-track {
  background-color: #E0E2E5;
  /* Culoarea fundalului track-ului */
}

/* Stilizarea scrollbarului pentru Firefox */
.ant-table-body {
  scrollbar-width: thin;
  /* Subțiază scrollbarul */
  scrollbar-color: #001529 #E0E2E5;
  /* Culoarea thumb-ului și a track-ului */
}