.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}
@media (min-width: 990px) {
  .ant-layout .ant-layout-sider-zero-width-trigger {
     top: -2.5em;
     left: 1rem;
     height: 2em;
     position: absolute;
  }
  .ant-layout .ant-layout-sider {
     transition-timing-function: ease-out;
     transition: all 0.47s, background 0s,;
  }
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

/* .form {
  max-width: 630px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  background: white;
  padding: 20px;
  margin-top: 30px;
} */